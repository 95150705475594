import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editable", "form", "input"];

  toggleEdit(event) {
    event.preventDefault();

    this.editableTarget.style.display = "none";
    this.formTarget.style.display = "flex";
    this.inputTarget.focus();
  }

  cancelEdit() {
    this.editableTarget.style.display = "flex";
    this.formTarget.style.display = "none";
  }
}
