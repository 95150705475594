import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "select",
    "file",
    "content",
    "video",
    "codeSnippet",
    "download",
    "embed",
  ];

  connect() {
    if (this.selectTarget.value) {
      this.toggleVisibility(this.selectTarget.value);
    }
  }

  toggle(event) {
    const { value } = event.target;
    this.toggleVisibility(value);
  }

  toggleVisibility(value) {
    this.hideAll();
    if (value === "FileContentBlock") {
      this.fileTarget.style.display = "block";
      this.downloadTarget.style.display = "block";
      this.embedTarget.style.display = "block";
    } else if (value === "RichTextContentBlock") {
      this.contentTarget.style.display = "block";
    } else if (value === "VideoContentBlock") {
      this.videoTarget.style.display = "block";
    } else if (value === "CodeSnippetContentBlock") {
      this.codeSnippetTarget.style.display = "block";
    }
  }

  hideAll() {
    this.fileTarget.style.display = "none";
    this.contentTarget.style.display = "none";
    this.videoTarget.style.display = "none";
    this.codeSnippetTarget.style.display = "none";
    this.downloadTarget.style.display = "none";
    this.embedTarget.style.display = "none";
  }
}
