// Entry point for the build script in your package.json

import "@hotwired/turbo-rails";
import "@hotwired/stimulus";
import "@rails/activestorage";
import "@rails/actiontext";
import "bootstrap";
// eslint-disable-next-line no-unused-vars
import Trix from "trix";
// tracking/analytics
// eslint-disable-next-line no-unused-vars
import ahoy from "ahoy.js";

import "./controllers";
import "./behaviors";
import "./utils/confirmDialog";

ahoy.configure({
  cookies: false,
});

document.addEventListener("turbo:load", () => {
  ahoy.trackView();
  ahoy.trackClicks("[data-click-tracking=true]");
  // ahoy.trackSubmits("form[data-submit-tracking=true]");
});

// required for google places api script callback function defined in app/layout/backoffice.html.erb
window.dispatchMapsEvent = function handleEvent(...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};
