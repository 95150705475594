import { get } from "@rails/request.js";

export async function regionCode(country, regionName) {
  const response = await get(
    `/api/regions/search.json?country=${country}&region=${regionName}`,
    {
      responseKind: "json",
    },
  );
  if (response.ok) {
    return response.json;
  }

  return null;
}

export async function regions(country) {
  const response = await get(`/api/regions.json?country=${country}`, {
    responseKind: "json",
  });
  if (response.ok) {
    const json = await response.json;
    return json;
  }

  return null;
}
