import { Controller } from "@hotwired/stimulus";
import { patch, post } from "@rails/request.js";
import Player from "@vimeo/player";
import throttle from "lodash.throttle";

// Connects to data-controller="embed-iframe"
export default class extends Controller {
  static targets = ["placeholder", "container"];

  static values = {
    urlOrIframe: String,
    userId: String,
    enabled: Boolean,
    videoTrackingEnabled: Boolean,
  };

  connect() {
    if (this.enabledValue) {
      this.show(null, { enableAutoplay: false });
      // TODO: If consent given, reload other iframes on page?
    }
  }

  disconnect() {
    this.cleanupVideoTracking();
  }

  show(event, { enableAutoplay = true } = {}) {
    if (event) {
      event.preventDefault();
    }

    if (this.userIdValue !== "0") {
      patch(`/api/users/${this.userIdValue}`, {
        body: { user: { embed_consent_given_at: new Date() } },
      });
    }

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.remove();
    }

    this.containerTarget.insertAdjacentHTML(
      "beforeend",
      this.buildIframe({ enableAutoplay }),
    );

    if (this.videoTrackingEnabledValue && this.isVimeoIframe()) {
      this.initVideoTracking();
    }
  }

  buildIframe({ enableAutoplay = true }) {
    if (this.embedCodeType(this.urlOrIframeValue) === "iframe") {
      return this.urlOrIframeValue;
    }
    if (this.embedCodeType(this.urlOrIframeValue) === "url") {
      return `
        <iframe src="${this.urlOrIframeValue}" frameborder="0" allow="${
          enableAutoplay ? "autoplay;" : ""
        } fullscreen">
        </iframe>
      `;
    }
    return ``;
  }

  embedCodeType(code) {
    if (this.verifyUrl(code)) {
      return "url";
    }
    if (code.includes("iframe")) {
      return "iframe";
    }
    return "other";
  }

  verifyUrl(url) {
    const res = url.match(/^(http(s)?:\/\/.)/);
    return res !== null;
  }

  isVimeoIframe() {
    return this.urlOrIframeValue.includes("vimeo.");
  }

  initVideoTracking() {
    const iframe = this.element.querySelector("iframe");
    if (!iframe) {
      return;
    }

    this.player = new Player(iframe);
    // Throttle the post request so it only happens every 1 seconds
    const throttledPost = throttle((data) => {
      post(`/api/vimeo/trackings`, {
        body: {
          video_tracking: {
            video_id: this.extractVimeoID(),
            seconds: Math.round(data.seconds),
            percentage: Math.round(data.percent * 100),
          },
        },
      });
    }, 5000); // 5 seconds

    this.player.on("timeupdate", throttledPost);
  }

  cleanupVideoTracking() {
    if (this.player) {
      this.player.destroy();
    }
  }

  // url:
  // https://vimeo.com/12345678
  // https://vimeo.com/channels/abc/12345678
  // https://player.vimeo.com/video/12345678
  //
  // iframe code snippet:
  // <iframe src="https://player.vimeo.com/video/242935944" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  // <p><a href="https://vimeo.com/242935944">Dieter Baacke &amp;uuml;ber Medienkompetenz</a> from <a href="https://vimeo.com/user4881144">jff_de</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
  extractVimeoID() {
    // Regex to extract Vimeo video ID from URLs
    const vimeoRegex =
      /https?:\/\/(?:www\.)?(?:vimeo\.com|player\.vimeo\.com)\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;

    // Regex to extract src URL from an iframe
    const iframeRegex = /<iframe.*?src=["'](.*?)["']/;

    if (this.embedCodeType(this.urlOrIframeValue) === "url") {
      const match = this.urlOrIframeValue.match(vimeoRegex);
      return match ? match[3] : null; // match[3] contains the video ID
    }
    if (this.embedCodeType(this.urlOrIframeValue) === "iframe") {
      const iframeMatch = this.urlOrIframeValue.match(iframeRegex);
      if (iframeMatch && iframeMatch[1]) {
        const url = iframeMatch[1]; // Extract the URL from the iframe
        const match = url.match(vimeoRegex);
        return match ? match[3] : null; // match[3] contains the video ID
      }
    }

    return null;
  }
}
