import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable"
export default class extends Controller {
  static targets = ["source", "dest"];

  connect() {
    this.sourceTargets.forEach((t) => {
      const params = {
        targetId: t.getAttribute("data-disable-on-check-target-id-param"),
      };

      this.toggleState(t.checked, params);
    });
  }

  toggle(event) {
    this.toggleState(event.target.checked, event.params);
  }

  toggleState(checked, { targetId }) {
    let dests = this.destTargets;

    if (targetId) {
      dests = dests.filter((t) => t.getAttribute("id").includes(targetId));
    }

    dests.forEach((t) => {
      if (checked) {
        t.removeAttribute("disabled");
      } else {
        t.setAttribute("disabled", true);
      }
    });
  }
}
