import { Controller } from "@hotwired/stimulus";
import {
  Chart,
  ArcElement,
  BarElement,
  PieController,
  BarController,
  CategoryScale,
  LinearScale,
} from "chart.js";

Chart.register(
  ArcElement,
  BarElement,
  PieController,
  BarController,
  CategoryScale,
  LinearScale,
);

// Connects to data-controller="charts"
export default class extends Controller {
  static targets = ["canvas"];

  static values = {
    labels: [],
    series: [],
    type: String,
  };

  connect() {
    const data = {
      labels: this.labelsValue,
      datasets: [
        {
          data: this.seriesValue,
          backgroundColor: ["#f7a600", "rgba(247, 166, 0, 0.5)"],
        },
      ],
    };

    const type = this.typeValue || "pie";
    const scales =
      type === "pie" || type === "doughnut"
        ? {}
        : {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          };

    const isMobile = window.matchMedia("(max-width: 991.98px)").matches;
    const cutout = isMobile ? 19 : 28;

    const options = {
      type,
      data,
      options: {
        hover: { mode: null },
        borderColor: "transparent",
        responsive: true,
        maintainAspectRatio: true,
        cutout,
        scales,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };

    // eslint-disable-next-line
    this.chart = new Chart(this.canvasTarget, options);
    this.resizeCanvas();
  }

  resizeCanvas() {
    const isMobile = window.matchMedia("(max-width: 991.98px)").matches;
    if (isMobile) {
      this.canvasTarget.parentNode.style.width = "80px";
      this.canvasTarget.parentNode.style.height = "78x";
    } else {
      this.canvasTarget.parentNode.style.width = "130px";
      this.canvasTarget.parentNode.style.height = "128px";
    }
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
