import debounce from "lodash.debounce";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["submit"];
  }

  initialize() {
    this.submit = debounce(this.submit.bind(this), 500);
  }

  submit() {
    this.submitTarget.click();
  }

  hideValidationMessage(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
