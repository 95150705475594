import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="update-license-price"
export default class extends Controller {
  static targets = [
    "maxMembersInput",
    "subTotalInput",
    "discountInput",
    "priceInput",
    "invoiceType",
  ];

  static values = {
    memberCounts: [9, 39, 79, 120, ">120"],
    licensePricesPerMemberCounts: {
      9: {
        default: 500,
        tools: 500,
        courses: 500,
        to_teach: 500,
        combo: 750,
        combo_to_teach: 1000,
      },
      39: {
        default: 1000,
        tools: 1000,
        courses: 1000,
        to_teach: 1500,
        combo: 1500,
        combo_to_teach: 2000,
      },
      79: {
        default: 1500,
        tools: 1500,
        courses: 1500,
        to_teach: 2500,
        combo: 2250,
        combo_to_teach: 3000,
      },
      120: {
        default: 2000,
        tools: 2000,
        courses: 2000,
        to_teach: 3500,
        combo: 3000,
        combo_to_teach: 4000,
      },
      ">120": {
        default: 2000,
        tools: 2500,
        courses: 2500,
        to_teach: 4500,
        combo: 4000,
        combo_to_teach: 5000,
      },
    },
    workshopPrices: {
      intro_courses: 250,
      intro_tools: 250,
      intro_ki: 250,
      consulting_courses: 100,
    },
  };

  connect() {
    this.updatePrice();
    this.toggleInvoiceData();
  }

  updatePrice() {
    const maxMembersCount = this.maxMembersInputTarget.value;
    let maxMembersCountForPrice = this.memberCountsValue.find(
      (memberCounts) => maxMembersCount <= +memberCounts,
    );

    if (maxMembersCount > 120) {
      maxMembersCountForPrice = ">120";
    }
    if (maxMembersCountForPrice) {
      document.querySelector('[data-key="price-combo"]').value =
        this.licensePricesPerMemberCountsValue[maxMembersCountForPrice].combo;
      document.querySelector('[data-key="price-courses"]').value =
        this.licensePricesPerMemberCountsValue[maxMembersCountForPrice].courses;
      document.querySelector('[data-key="price-tools"]').value =
        this.licensePricesPerMemberCountsValue[maxMembersCountForPrice].tools;
      document.querySelector('[data-key="price-to_teach"]').value =
        this.licensePricesPerMemberCountsValue[
          maxMembersCountForPrice
        ].to_teach;
      document.querySelector('[data-key="price-combo_to_teach"]').value =
        this.licensePricesPerMemberCountsValue[
          maxMembersCountForPrice
        ].combo_to_teach;
    }

    this.recalculatePrice();
  }

  recalculatePrice() {
    let price = 0;
    let discount = 0;
    document
      .querySelectorAll('[data-type="offerable_item"]')
      .forEach((input) => {
        if (input.checked === true) {
          price += parseFloat(
            document.getElementById(
              input.id.replace("offerable_item_id", "price"),
            ).value || 0,
          );
          discount += parseFloat(
            document.getElementById(
              input.id.replace("offerable_item_id", "discount"),
            ).value || 0,
          );
        } else if (input.type === "text" && input.value) {
          price += parseFloat(
            document.getElementById(input.id.replace("title", "price")).value ||
              0,
          );
          discount += parseFloat(
            document.getElementById(input.id.replace("title", "discount"))
              .value || 0,
          );
        }
      });

    this.discountInputTarget.value = discount || 0;
    this.subTotalInputTarget.value = price || 0;
    this.priceInputTarget.value = price - discount || 0;
  }

  toggleInvoiceData() {
    if (this.invoiceTypeTarget.value === "pdf_xml") {
      document.querySelector(".xml-invoice-fields").classList.remove("d-none");
    } else {
      document.querySelector(".xml-invoice-fields").classList.add("d-none");
    }
  }
}
