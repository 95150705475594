import { Controller } from "@hotwired/stimulus";
import * as regionsApi from "../api/regions";

export default class extends Controller {
  static targets = ["countrySelect", "regionSelect"];

  async update() {
    const country = this.countrySelectTarget.value;

    if (country && country.length > 0) {
      const json = await regionsApi.regions(country);
      this.regionSelectTarget.innerHTML = "";
      this.regionSelectTarget.innerHTML += '<option value=""></option>';
      for (let i = 0; i < json.length; i += 1) {
        const opt = json[i];
        this.regionSelectTarget.innerHTML += `<option value="${opt.value}">${opt.name}</option>`;
      }
    }
  }
}
