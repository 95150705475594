import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contact", "adminEmails"];

  static values = { emails: Object };

  connect() {
    this.changeContact();
  }

  changeContact() {
    if (this.contactTarget.value) {
      const contactId = this.contactTarget.value;
      this.adminEmailsTarget.value = this.emailsValue[contactId];
    }
  }
}
