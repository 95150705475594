import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    seconds: Number,
  };

  connect() {
    setTimeout(() => {
      window.location.reload();
    }, this.secondsValue * 1000);
  }
}
