import { Controller } from "@hotwired/stimulus";
import { Chart, ArcElement, PieController, Legend, Tooltip } from "chart.js";

Chart.register(ArcElement, PieController, Legend, Tooltip);

export default class extends Controller {
  static targets = ["canvas"];

  static values = {
    labels: [],
    series: [],
  };

  connect() {
    const data = {
      labels: this.labelsValue,
      datasets: [
        {
          data: this.seriesValue,
          backgroundColor: [
            "#f7a600",
            "#22b5b7",
            "#b3177b",
            "#bac829",
            "#de80d7",
            "#522060",
          ],
        },
      ],
    };

    const options = {
      type: "pie",
      data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: window.matchMedia("(max-width: 991.98px)").matches
          ? 0.9
          : 1.8,
        plugins: {
          legend: {
            labels: {
              textAlign: "left",
              font: { size: 14 },
            },
            position: window.matchMedia("(max-width: 991.98px)").matches
              ? "bottom"
              : "right",
          },
        },
      },
    };

    // eslint-disable-next-line
    new Chart(this.canvasTarget, options);
  }
}
