import Cookies from "js-cookie";
import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  close() {
    this.setCookie();
    this.removeElement();
    // clear turbolinks cache so cookie banner does not reappear
    Turbo.clearCache();
  }

  setCookie() {
    const isSecure = window.location.protocol === "https:";
    Cookies.set("cookie_eu_consented", true, {
      path: "/",
      expires: 365,
      secure: isSecure,
    });
  }

  removeElement() {
    this.element.parentNode.removeChild(this.element);
  }
}
