import { Controller } from "@hotwired/stimulus";
import * as regionsApi from "../api/regions";

export default class extends Controller {
  static targets = ["field"];

  static values = {
    prefix: String,
  };

  connect() {
    if (typeof google !== "undefined") {
      this.initPlaces();
    }
  }

  initPlaces() {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this),
    );
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      return;
    }

    this.fillInAddress(place);
  }

  async fillInAddress(place) {
    document.querySelector(`#${this.prefixValue}_title`).value = place.name;

    // prefill country input first and ensure that region select options for country are prefilled
    await this.prefillCountryField(
      {
        selector: `#${this.prefixValue}_country_code`,
        componentType: "short_name",
      },
      this.findAddressComponent(place, "country"),
    );

    await this.prefillRegionField(
      {
        selector: `#${this.prefixValue}_region`,
        componentType: "long_name",
      },
      this.findAddressComponent(place, "country"),
      this.findAddressComponent(place, "administrative_area_level_1"),
    );

    Object.keys(this.availableMappings).forEach((key) => {
      const mapping = this.availableMappings[key];
      const addressComponent = this.findAddressComponent(place, key);
      if (addressComponent) {
        this.prefillField(mapping, addressComponent);
      }
    });
  }

  async prefillRegionField(
    mapping,
    countryAddressComponent,
    regionAddressComponent,
  ) {
    if (countryAddressComponent && regionAddressComponent) {
      const { regionCode } = await regionsApi.regionCode(
        countryAddressComponent.short_name,
        regionAddressComponent[mapping.componentType],
      );

      const element = document.querySelector(
        `#${this.prefixValue}_region_code`,
      );
      element.value = regionCode;
    }
  }

  // call stimulus region_controller#update method to prefill options based on selected country
  async prefillCountryField(mapping, addressComponent) {
    if (addressComponent) {
      this.prefillField(mapping, addressComponent);

      const regionController =
        this.application.getControllerForElementAndIdentifier(
          this.element,
          "region",
        );
      await regionController.update();
    }
  }

  findAddressComponent(place, key) {
    return place.address_components.find((ac) => key === ac.types[0]);
  }

  prefillField(mapping, addressComponent) {
    const element = document.querySelector(mapping.selector);
    const newValue = addressComponent[mapping.componentType];
    element.value = newValue;
  }

  get availableMappings() {
    return {
      postal_code: {
        selector: `#${this.prefixValue}_zipcode`,
        componentType: "long_name",
      },
      route: {
        selector: `#${this.prefixValue}_street`,
        componentType: "long_name",
      },
      locality: {
        selector: `#${this.prefixValue}_city`,
        componentType: "long_name",
      },
    };
  }

  keydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
}
