import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable"
export default class extends Controller {
  static targets = ["dest"];

  toggle({ params: { targetId } }) {
    let dests = this.destTargets;

    if (targetId) {
      dests = dests.filter((t) => t.getAttribute("id").includes(targetId));
    }

    const check = dests.some((t) => !t.checked);
    dests.forEach((t) => {
      // eslint-disable-next-line no-param-reassign
      t.checked = check;
    });
  }
}
