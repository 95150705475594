import { Controller } from "@hotwired/stimulus";
import Combobox from "@github/combobox-nav";

// Connects to data-controller="navsearch"
export default class extends Controller {
  static get targets() {
    return ["input", "list", "spinner", "dropdown", "clear"];
  }

  connect() {
    this.navBarEl = document.querySelector("#main-navbar");
  }

  disconnect() {
    this.combobox?.destroy();
  }

  listTargetConnected() {
    this.start();
  }

  start() {
    this.showSearchInput();
    this.combobox?.destroy();
    this.combobox = new Combobox(this.inputTarget, this.listTarget);
    this.combobox.start();

    this.listTarget.addEventListener("combobox-commit", (event) => {
      event.target.click();
    });
  }

  stop() {
    this.hideSearchInput();
    this.combobox?.stop();

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add("hidden");
    }
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.remove("show");
    }
  }

  handleInput() {
    if (this.hasSpinnerTarget) {
      if (this.inputTarget.value.length > 0) {
        this.spinnerTarget.classList.remove("hidden");
      } else {
        this.spinnerTarget.classList.add("hidden");
      }
    }

    if (this.hasDropdownTarget) {
      if (this.inputTarget.value.length > 0) {
        this.dropdownTarget.classList.add("show");
      } else {
        this.dropdownTarget.classList.remove("show");
      }
    }

    if (this.hasListTarget) {
      this.listTarget.replaceChildren();
    }
  }

  handleSearchIconClick() {
    this.showSearchInput();
    this.inputTarget.focus();
  }

  showSearchInput() {
    if (this.navBarEl) {
      this.navBarEl.classList.add("navbar--search--active");

      if (this.hasClearTarget) {
        this.clearTarget.classList.remove("hidden");
      }
    }
  }

  searchIsVisible() {
    return this.navBarEl.classList.contains("navbar--search--active");
  }

  hideSearchInput() {
    if (this.navBarEl && this.searchIsVisible()) {
      this.navBarEl.classList.remove("navbar--search--active");
      this.inputTarget.value = "";
      this.clearTarget.classList.add("hidden");
      this.listTarget.replaceChildren();
      this.dropdownTarget.classList.remove("show");
    }
  }

  handleOutsideClick(event) {
    if (this.element === event.target || this.element.contains(event.target))
      return;

    if (this.searchIsVisible()) {
      this.hideSearchInput();
    }
  }
}
