import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element);
    this.element.addEventListener("hide.bs.modal", this.handleHide.bind(this));
    this.modal.show();
  }

  handleHide() {
    this.element.remove();
  }
}
