import { Controller } from "@hotwired/stimulus";
import PDFObject from "pdfobject";

export default class extends Controller {
  connect() {
    PDFObject.embed(this.url, this.element, { pdfOpenParams: { view: "Fit" } });
  }

  get url() {
    return this.data.get("url");
  }
}
