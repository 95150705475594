import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "course",
    "allCoursesRadioButton",
    "type",
    "radioButtons",
    "coursesEnabled",
    "toolsEnabled",
    "toolsEndAt",
    "toTeachEnabled",
    "toTeachEndAt",
    "notAllCoursesRadioButton",
  ];

  connect() {
    if (this.allCoursesRadioButtonTarget.checked) {
      this.courseTarget.style.display = "none";
    }

    if (this.typeTarget.value === "Bundle") {
      this.courseTarget.style.display = "none";
      this.radioButtonsTarget.style.display = "none";
    }
  }

  selectAllCourses(event) {
    if (event.target.value === "1") {
      this.courseTarget.style.display = "none";
    } else {
      this.courseTarget.style.display = "block";
    }
  }

  changeCoursesEnabled(event) {
    if (!event.target.checked) {
      this.allCoursesRadioButtonTarget.checked = false;
      this.notAllCoursesRadioButtonTarget.checked = true;
    } else {
      this.allCoursesRadioButtonTarget.checked = true;
      this.notAllCoursesRadioButtonTarget.checked = false;
    }

    this.connect();
  }

  changeToolsEnabled(event) {
    if (event.target.checked) {
      this.toolsEndAtTarget.value = document.getElementById(
        "group_order_ends_at",
      ).value;
    } else {
      this.toolsEndAtTarget.value = null;
    }

    this.connect();
  }

  changeToTeachEnabled(event) {
    if (event.target.checked) {
      this.toTeachEndAtTarget.value = document.getElementById(
        "group_order_ends_at",
      ).value;
    } else {
      this.toTeachEndAtTarget.value = null;
    }

    this.connect();
  }

  changeGroupItemEnabled(event) {
    document
      .getElementById(`collapse-${event.target.dataset.itemId}`)
      .classList.toggle("d-none");
    this.connect();
  }
}
