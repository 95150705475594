import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "package",
    "maxMembersCount",
    "price",
    "priceTools",
    "discount",
    "discountTools",
    "copyMembershipsCheckbox",
  ];

  static values = { packages: Object, membershipsCount: Number };

  changePackage(event) {
    this.setMaxMembers(event);
    this.setPrice(event);
    this.toggleCheckbox();
  }

  changeMaxMembersCount(event) {
    if (this.membershipsCountValue) {
      this.toggleCheckbox(event.target.value);
    }
  }

  changeToolsEnabled(event) {
    if (!this.hasPriceToolsTarget) return;

    if (event.target.checked && !(this.priceToolsTarget.value > 0)) {
      this.priceToolsTarget.value = 500;
    } else if (!event.target.checked) {
      this.priceToolsTarget.value = null;
      this.discountToolsTarget.value = null;
    }
  }

  changeCoursesEnabled(event) {
    if (!this.hasPriceToolsTarget) return;

    if (event.target.checked && !(this.priceTarget.value > 0)) {
      this.setPrice(event);
    } else if (!event.target.checked) {
      this.priceTarget.value = null;
      this.discountTarget.value = null;
    }
  }

  setMaxMembers(event) {
    if (!this.hasMaxMembersCountTarget) return;

    const newMaxMembersCount =
      this.packagesValue[event.target.value].max_members_count;
    this.maxMembersCountTarget.value = newMaxMembersCount;
  }

  setPrice(event) {
    if (!this.hasPriceTarget) return;

    if (!this.packagesValue[event.target.value]) {
      this.priceTarget.value =
        this.packagesValue[this.packageTarget.value].price;
    } else {
      this.priceTarget.value = this.packagesValue[event.target.value].price;
    }
  }

  toggleCheckbox() {
    if (!this.hasCopyMembershipsCheckboxTarget) return;

    if (this.membershipsCountValue > this.maxMembersCountTarget.value) {
      this.copyMembershipsCheckboxTarget.disabled = true;
    } else {
      this.copyMembershipsCheckboxTarget.disabled = false;
    }
  }
}
