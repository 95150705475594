export function metaContent(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element && element.content;
}

export function getRailsEnv() {
  return metaContent("rails-env");
}

export function isDevelopment() {
  return metaContent("rails-env") === "development";
}

export function isProduction() {
  return metaContent("rails-env") === "production";
}

export function getCSRFToken() {
  return metaContent("csrf-token");
}
