import { Controller } from "@hotwired/stimulus";

import * as invoicesApi from "../api/invoices";

export default class extends Controller {
  static targets = ["field"];

  changed(event) {
    setTimeout(() => {
      this.invoiceNumberChanged(event.target.value);
    }, 0);
  }

  async invoiceNumberChanged(invoiceId) {
    const data = await invoicesApi.invoice(invoiceId);
    if (data) {
      this.fillInAddress(data);
    }
  }

  async fillInAddress(data) {
    if (document.querySelector("#group_price")) {
      document.querySelector("#group_price").value = data.totalNetAmount;
    }
    if (document.querySelector("#group_invoice_number")) {
      document.querySelector("#group_invoice_number").value =
        data.invoiceNumber;
    }
  }
}
