import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="embedded-contact-select"
export default class extends Controller {
  static targets = ["link", "select"];

  static values = { url: String };

  connect() {
    this.toggleLink(this.selectTarget.value);
  }

  updateLink(event) {
    this.toggleLink(event.target.value);
  }

  toggleLink(value) {
    if (value && value.length) {
      this.linkTarget.href = `${this.urlValue}?selected=${value}`;
      this.linkTarget.classList.remove("d-none");
    } else {
      this.linkTarget.classList.add("d-none");
    }
  }
}
