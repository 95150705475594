import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    redirectUrl: String,
  };

  connect() {
    const chargebeeSite = document
      .querySelector("meta[name='chargebee-site']")
      .getAttribute("content");

    this.chargebeeInstance = window.Chargebee.init({ site: chargebeeSite });
  }

  async openCheckoutNew(event) {
    event.preventDefault();

    this.chargebeeInstance.openCheckout({
      hostedPage: () =>
        get(this.urlValue, { responseKind: "json" }).then((r) => r.json),
      error: (e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      },
      success(hostedPageId) {
        window.location.replace(`${this.redirectUrlValue}?id=${hostedPageId}`);
      },
      close: () => window.location.replace(this.redirectUrlValue),
    });
  }

  async openCheckoutUpdate(event) {
    event.preventDefault();

    this.chargebeeInstance.openCheckout({
      hostedPage: () =>
        get(this.urlValue, { responseKind: "json" }).then((r) => r.json),
      error: (e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      },
      close: () => window.location.replace(this.redirectUrlValue),
    });
  }

  openPortalSession(event) {
    event.preventDefault();
    this.chargebeeInstance.setPortalSession(() =>
      get(this.urlValue, { responseKind: "json" }).then((r) => r.json),
    );

    const chargebeePortal = this.chargebeeInstance.createChargebeePortal();

    chargebeePortal.open({
      error: (e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      },
      close: () => window.location.replace(this.redirectUrlValue),
    });
  }

  // only required if we support multiple plans and want to allow switching plans
  // openCheckoutExisting(event) {
  //   event.preventDefault();
  //   const { subscriptionId } = e.target.dataset;
  //   const { planId } = e.target.dataset;

  //   this.chargebeeInstance.openCheckout({
  //     hostedPage: () =>
  //       get(this.urlValue, { responseKind: "json" }).then((r) => r.json),
  //     error: (e) => {
  //       // eslint-disable-next-line no-console
  //       console.error(e);
  //     },
  //   });
  // }
}
