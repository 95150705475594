import axios from "axios";
import humps from "humps";

import { getCSRFToken } from "../utils/meta";

const instance = axios.create({
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": getCSRFToken(),
  },
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

export default instance;
