import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.hintElement = this.element.parentNode.querySelector(".form-text");
  }

  updateWordCount() {
    const charCount = this.element.value.trim().length;
    if (charCount > this.max) {
      this.hintElement.innerHTML = `<span class="text-danger">Mehr als ${this.max} Zeichen eingegeben</span>`;
    } else {
      this.hintElement.innerHTML = `Noch ${this.max - charCount} Zeichen`;
    }
  }

  get max() {
    return this.data.get("max");
  }
}
