import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["orderStartsAt", "orderEndsAt", "orderDuration"];

  changeOrderEndsAt() {
    if (
      this.hasOrderEndsAtTarget &&
      this.hasOrderStartsAtTarget &&
      this.orderStartsAtTarget.value &&
      this.hasOrderDurationTarget &&
      this.orderDurationTarget.value
    ) {
      const date = new Date(this.orderStartsAtTarget.value);

      date.setDate(
        date.getDate() + Number.parseInt(this.orderDurationTarget.value, 10),
      );
      const str = date.toISOString().split("T")[0];
      this.orderEndsAtTarget.value = str;
    }
  }
}
