import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["course", "bundle", "type"];

  connect() {
    if (this.typeTarget.value === "Course") {
      this.hideBundle();
    } else if (this.typeTarget.value === "Bundle") {
      this.hideCourse();
    }
  }

  toggle(event) {
    if (event.target.value === "Course") {
      this.hideBundle();
    } else if (event.target.value === "Bundle") {
      this.hideCourse();
    }
  }

  hideBundle() {
    this.bundleTarget.style.display = "none";
    this.courseTarget.style.display = "block";
    if (!this.isEditMode) {
      this.bundleTarget.disabled = "disabled";
      this.courseTarget.disabled = null;
    }
  }

  hideCourse() {
    this.bundleTarget.style.display = "block";
    this.courseTarget.style.display = "none";
    if (!this.isEditMode) {
      this.bundleTarget.disabled = null;
      this.courseTarget.disabled = "disabled";
    }
  }

  get isEditMode() {
    return this.data.get("edit") === "true";
  }
}
