import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["entries", "pagination", "spinner"];

  initialize() {
    const options = {
      rootMargin: "200px",
    };

    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      options,
    );
  }

  connect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.observe(this.paginationTarget);
    }
  }

  disconnect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.unobserve(this.paginationTarget);
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  async loadMore() {
    const nextPage = this.paginationTarget.querySelector("a[rel='next']");
    if (nextPage == null) {
      return;
    }
    const url = nextPage.href;

    this.spinnerTarget.classList.add("active");

    const response = await get(url, { responseKind: "json" });
    if (response.ok) {
      this.spinnerTarget.classList.remove("active");
      const json = await response.json;
      this.entriesTarget.insertAdjacentHTML("beforeend", json.entries);
      this.paginationTarget.innerHTML = json.pagination;
    } else {
      // eslint-disable-next-line no-console
      console.log("ERROR", response);
    }
  }
}
