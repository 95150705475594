import { Controller } from "@hotwired/stimulus";
import throttle from "lodash.throttle";

// Connects to data-controller="fixed-footer"
export default class extends Controller {
  static classes = ["hide"];

  connect() {
    this.lastScroll = 0;
    this.throttledHandleScrollEvent = throttle(
      this.handleScrollEvent.bind(this),
      100,
    );
    window.addEventListener("scroll", this.throttledHandleScrollEvent);
  }

  disconnect() {
    window.removeEventListener("scroll", this.throttledHandleScrollEvent);
  }

  handleScrollEvent() {
    const { body } = document;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";

    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      return;
    }

    if (
      currentScroll > this.lastScroll &&
      !body.classList.contains(scrollDown)
    ) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);

      this.element.classList.add(this.hideClass);
    } else if (
      currentScroll < this.lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);

      this.element.classList.remove(this.hideClass);
    }
    this.lastScroll = currentScroll;
  }
}
