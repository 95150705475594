import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2/dist/sweetalert2";
import { createVideo, uploadVideo } from "../api/video";

export default class extends Controller {
  static targets = [
    "input",
    "file",
    "progress",
    "progressBar",
    "statusMessage",
    "submitButton",
    "cancelButton",
  ];

  disconnect() {
    clearTimeout(this.videoTimeout);
  }

  async fileChanged(event) {
    const file = event.target.files[0];

    this.disableFormButtons();
    this.disableVideoIdInput();
    this.displayStatusMessage(this.element.dataset.videoUpload);

    try {
      const { id, _link, uploadLink } = await createVideo(file);

      this.hideStatusMessage();
      this.showProgress();

      await uploadVideo(file, uploadLink, {
        onProgress: (percentage) => this.updateProgressBar(percentage),
      });
      this.inputTarget.value = id;
      this.fileTarget.disabled = true;

      this.enableVideoIdInput();
      this.resetProgress();
      this.enableFormButtons();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Vimeo upload video", file.name, error);

      Swal.fire({
        title: this.element.dataset.title,
        text: this.element.dataset.text,
        confirmButtonText: "Okay",
      }).then(() => {
        this.enableVideoIdInput();
        this.hideStatusMessage();
        this.resetProgress();
      });
    }
  }

  updateProgressBar(percentage) {
    this.progressBarTarget.style.width = `${percentage}%`;
    this.progressBarTarget.setAttribute("aria-valuenow", percentage);
  }

  showProgress() {
    this.progressTarget.style.display = "flex";
  }

  resetProgress() {
    this.progressTarget.style.display = "none";
    this.progressBarTarget.style.width = "0%";
    this.progressBarTarget.setAttribute("aria-valuenow", 0);
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }

  displayStatusMessage(message) {
    this.statusMessageTarget.style.display = "block";
    this.statusMessageTarget.innerHTML = `
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      ${message}
    `;
  }

  hideStatusMessage() {
    this.statusMessageTarget.style.display = "none";
  }

  disableFormButtons() {
    this.submitButtonTarget.disabled = true;
    this.cancelButtonTarget.disabled = true;
  }

  enableFormButtons() {
    this.submitButtonTarget.disabled = false;
    this.cancelButtonTarget.disabled = false;
  }

  disableVideoIdInput() {
    this.inputTarget.disabled = true;
  }

  enableVideoIdInput() {
    this.inputTarget.disabled = false;
  }
}
