import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import debounce from "lodash.debounce";

import axios from "../api/axios";
import toQueryString from "../utils/toQueryString";

export default class extends Controller {
  static targets = ["select"];

  static values = {
    url: String,
    disabled: Boolean,
    urlParams: Object,
    removeItemsButton: { type: Boolean, default: false },
  };

  initialize() {
    this.debouncedSearch = debounce(this.requestSearch.bind(this), 200);
  }

  connect() {
    if (!this.hasSelectTarget) return;

    this.choices = new Choices(this.selectTarget, {
      placeholder: true,
      placeholderValue: "Suche...",
      // searchPlaceholderValue: "Search...",
      searchFloor: 2, // min 2 characters
      searchResultLimit: 30,
      loadingText: "Laden...",
      noResultsText: "Keine Ergebnisse",
      noChoicesText: "Keine Ergebnisse",
      itemSelectText: "Klicke, um auszuwählen",
      removeItemButton: this.removeItemsButtonValue,
    });

    // this.lookupTimeout = null;

    // start debounced search
    this.selectTarget.addEventListener("search", this.debouncedSearch);

    // clear choices after user selected an item
    this.selectTarget.addEventListener("choice", () => {
      this.choices.clearChoices();
    });

    this.disableChoices(this.disabledValue);
  }

  async search(query) {
    const response = await axios.get(this.buildQueryUrl(query));
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(response.toString());
  }

  buildQueryUrl(query) {
    const urlParams = this.urlParamsValue || {};
    urlParams.query = query;

    return `${this.urlValue}${toQueryString(urlParams)}`;
  }

  async requestSearch() {
    const query = this.choices.input.value;
    const results = await this.search(query);
    this.choices.clearChoices();
    this.choices.setChoices(results, "value", "label", true);
  }

  disconnect() {
    if (this.choices) {
      this.choices.destroy();
    }

    if (this.lookupTimeout) {
      clearTimeout(this.lookupTimeout);
    }
  }

  toggleDisabled(event) {
    this.disableChoices(event.target.checked);
  }

  disableChoices(disabled) {
    if (disabled) {
      this.choices.disable();
      this.choices.clearStore();
    } else {
      this.choices.enable();
    }
  }

  addUrlParams(event) {
    if (event.target.value === "") {
      this.urlParamsValue = {
        ...this.urlParamsValue,
        [event.params.urlKey]: undefined,
      };
    } else {
      this.urlParamsValue = {
        ...this.urlParamsValue,
        [event.params.urlKey]: event.target.value,
      };
    }
    this.requestSearch();
  }
}
