import { Controller } from "@hotwired/stimulus";
import { Carousel } from "bootstrap";

// Connects to data-controller="carousel"
export default class extends Controller {
  connect() {
    this.carousel = new Carousel(this.element);
  }

  disconnect() {
    this.carousel.dispose();
  }
}
