import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Swal from "sweetalert2/dist/sweetalert2";

export default class extends Controller {
  show(event) {
    const el = this.element;
    event.preventDefault();

    Swal.fire({
      title: el.dataset.title || "Bist du sicher?",
      text: el.dataset.text,
      icon: el.dataset.icon,
      imageUrl: el.dataset.imageUrl,
      imageWidth: el.dataset.imageWidth,
      imageHeight: el.dataset.imageHeight,
      showCancelButton: true,
      confirmButtonText: el.dataset.button || "Ja",
      cancelButtonText: el.dataset.cancelButton || "Abbrechen",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const form = this.element.closest("form");
        if (form) {
          Turbo.navigator.submitForm(form);
        }
      } else {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    });
  }
}
