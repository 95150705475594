import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="vimeo"
export default class extends Controller {
  static targets = ["image", "overlay"];

  static values = {
    videoId: String,
  };

  showVideo(event) {
    event.preventDefault();
    if (this.hasImageTarget) {
      this.imageTarget.remove();
    }

    if (this.hasOverlayTarget) {
      this.overlayTarget.remove();
    }

    const [id, hash] = this.videoIdValue.split("/");

    this.element.insertAdjacentHTML(
      "beforeend",
      `
      <div class="embedded-content__responsive-container embedded-content__responsive-container--vimeo">
        <iframe src="https://player.vimeo.com/video/${id}?dnt=1&autoplay=1${
          hash ? `&h=${hash}` : ""
        }" frameborder="0" allow="autoplay; fullscreen" allowfullscreen>
        </iframe>
      </div>
    `,
    );
  }
}
