import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

import { patch } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    group: String,
    handle: String,
  };

  connect() {
    const options = {
      group: this.groupValue,
      animation: 150,
      handle: this.handleValue,
      onEnd: this.end.bind(this),
    };
    this.sortable = Sortable.create(this.element, options);
  }

  end(event) {
    const { id } = event.item.dataset;
    const data = new FormData();
    data.append("position", event.newIndex + 1);
    if (event.to.dataset.parent) {
      data.append("parent", event.to.dataset.parent);
    }

    patch(this.urlValue.replace(":id", id), { body: data });
  }
}
