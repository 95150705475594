import * as tus from "tus-js-client";

import axios from "./axios";

export async function createVideo({ name, size }) {
  const url = "/api/vimeo/videos";
  const response = await axios.post(url, { name, size });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response);
}

export async function uploadVideo(
  file,
  uploadLink,
  { onProgress = () => {} } = {},
) {
  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      uploadUrl: uploadLink,
      endpoint: uploadLink,
      retryDelays: [0, 1000, 3000, 5000],
      onError: (error) => {
        reject(error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        onProgress(percentage);
      },
      onSuccess: () => {
        resolve();
      },
    });

    upload.start();
  });
}
