import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="subnav"
export default class extends Controller {
  static get targets() {
    return ["scrollContainer"];
  }

  toggle() {
    this.scrollContainerTarget.classList.toggle("enable-scroll");
  }

  handleOutsideClick(event) {
    if (this.element === event.target || this.element.contains(event.target))
      return;

    this.scrollContainerTarget.classList.add("enable-scroll");
  }
}
