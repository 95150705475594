import { get } from "@rails/request.js";

// eslint-disable-next-line import/prefer-default-export
export async function invoice(id) {
  const response = await get(`/api/lexoffice_invoices/${id}`, {
    responseKind: "json",
  });
  if (response.ok) {
    return response.json;
  }
  return null;
}
