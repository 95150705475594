import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "dest"];

  static classes = ["hide"];

  connect() {
    this.toggleState(this.sourceTarget.checked);
  }

  toggle(event) {
    this.toggleState(event.target.checked);
  }

  toggleState(checked) {
    this.destTargets.forEach((t) => {
      if (checked) {
        t.classList.remove(this.hideClass);
      } else {
        t.classList.add(this.hideClass);
      }
    });
  }
}
