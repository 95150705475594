import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="num-of-employees-to-max-members-count"

let maxMembersCount;
export default class extends Controller {
  static targets = ["maxMembersInput", "numOfEmployeesInput"];

  static values = {
    memberCounts: [9, 39, 79, 120, ">120"],
    pricesPerMemberCounts: {
      9: {
        default: 500,
        tools: 500,
        courses: 500,
        to_teach: 500,
        combo: 750,
        combo_to_teach: 1000,
      },
      39: {
        default: 1000,
        tools: 1000,
        courses: 1000,
        to_teach: 1500,
        combo: 1500,
        combo_to_teach: 2000,
      },
      79: {
        default: 1500,
        tools: 1500,
        courses: 1500,
        to_teach: 2500,
        combo: 2250,
        combo_to_teach: 3000,
      },
      120: {
        default: 2000,
        tools: 2000,
        courses: 2000,
        to_teach: 3500,
        combo: 3000,
        combo_to_teach: 4000,
      },
      ">120": {
        default: 2000,
        tools: 2500,
        courses: 2500,
        to_teach: 4500,
        combo: 4000,
        combo_to_teach: 5000,
      },
    },
  };

  numOfEmployeesInputTargetConnected() {
    this.selectCount();
  }

  selectCount() {
    const numOfEmployees = this.numOfEmployeesInputTarget.value;
    maxMembersCount = this.memberCountsValue.find(
      (memberCounts) => numOfEmployees <= +memberCounts,
    );
    if (numOfEmployees > 120) {
      maxMembersCount = ">120";
    }

    if (maxMembersCount) {
      // select member count radio button
      this.select(maxMembersCount);
      // show price
      this.updatePrice(maxMembersCount);
    }
  }

  refreshPrice(event) {
    const count = event.target.value;
    this.updatePrice(count);
  }

  select(count) {
    this.maxMembersInputTargets.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.checked = input.value.toString() === count.toString();
    });
  }

  updatePrice(count) {
    if (count === ">120") {
      document
        .getElementById("pricing-table--non-offerable")
        .classList.remove("d-none");
      document
        .getElementById("pricing-table--offerable")
        .classList.add("d-none");
      document.getElementById("form-wizard-actions").classList.add("d-none");
    } else {
      document
        .getElementById("pricing-table--offerable")
        .classList.remove("d-none");
      document.getElementById("form-wizard-actions").classList.remove("d-none");
      document
        .getElementById("pricing-table--non-offerable")
        .classList.add("d-none");
      this.showPrice(count);
    }
  }

  showPrice(count) {
    let price = this.pricesPerMemberCountsValue[count].courses;
    document.getElementById("licence_price-courses").innerHTML = price;

    price = this.pricesPerMemberCountsValue[count].tools;
    document.getElementById("licence_price-tools").innerHTML = price;

    price = this.pricesPerMemberCountsValue[count].combo;
    document.getElementById("licence_price-combo").innerHTML = price;

    price = this.pricesPerMemberCountsValue[count].to_teach;
    document.getElementById("licence_price-to_teach").innerHTML = price;

    price = this.pricesPerMemberCountsValue[count].combo_to_teach;
    document.getElementById("licence_price-combo_to_teach").innerHTML = price;
  }
}
