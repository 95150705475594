import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "dest"];

  static values = {
    option: String,
    initialOption: String,
  };

  static classes = ["hide"];

  connect() {
    if (this.initialOptionValue) {
      this.toggleState(this.initialOptionValue);
    } else {
      this.toggleState(this.sourceTarget.value);
    }
  }

  toggle(event) {
    this.toggleState(event.target.value);
  }

  toggleState(value) {
    this.destTargets.forEach((t) => {
      if (this.optionValue === value) {
        t.classList.remove(this.hideClass);
      } else {
        t.classList.add(this.hideClass);
      }
    });
  }
}
