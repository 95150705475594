import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
// Connects to data-controller="multi-select"
export default class extends Controller {
  static values = {
    options: Array,
    maxItemCount: { type: Number, default: -1 },
    renderChoiceLimit: { type: Number, default: -1 },
  };

  connect() {
    this.choices = new Choices(this.element, {
      removeItems: true,
      removeItemButton: true,
      loadingText: "Laden...",
      noResultsText: "Keine Ergebnisse",
      noChoicesText: "Keine Ergebnisse",
      itemSelectText: "Klicke, um auszuwählen",
      allowHTML: true,
      maxItemCount: this.maxItemCountValue,
      maxItemText: (maxItemCount) =>
        `Nur ${maxItemCount} Kurse können ausgewählt werden`,
      renderChoiceLimit: this.renderChoiceLimitValue,
    });
  }
}
