import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

// Connects to data-controller="tooltip"
export default class extends Controller {
  connect() {
    this.tooltip = new Tooltip(this.element, {
      html: true,
      sanitize: false,
      boundary: document.body,
      trigger: "hover", // Remove 'focus' from defaults to make tooltip disappear on focused links
    });
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.dispose();
    }
  }
}
