import Swal from "sweetalert2/dist/sweetalert2";

document.addEventListener("turbo:load", () => {
  const elements = document.querySelectorAll("[data-confirm]");
  elements.forEach((el) => {
    el.addEventListener("click", (event) => {
      if (el.getAttribute("data-confirm") === null) {
        return;
      }
      event.preventDefault();

      Swal.fire({
        title: el.dataset.confirm || "Bist du sicher?",
        text: el.dataset.text,
        icon: el.dataset.icon,
        imageUrl: el.dataset.imageUrl,
        imageWidth: el.dataset.imageWidth,
        imageHeight: el.dataset.imageHeight,
        showCancelButton: true,
        confirmButtonText: el.dataset.button || "Ja",
        cancelButtonText: el.dataset.cancelButton || "Abbrechen",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          el.removeAttribute("data-confirm");
          el.click();
        } else {
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      });
    });
  });
});
