import { Controller } from "@hotwired/stimulus";
import axios from "../api/axios";

export default class extends Controller {
  static targets = [
    "title",
    "street",
    "zipcode",
    "city",
    "countryCode",
    "schoolType",
    "schoolTypeCustom",
    "select",
    "all",
  ];

  static values = { url: String };

  connect() {
    if (this.selectTarget.value) {
      this.allTarget.classList.remove("d-none");
    }
  }

  resetForm() {
    this.titleTarget.value = "";
    this.streetTarget.value = "";
    this.zipcodeTarget.value = "";
    this.cityTarget.value = "";
    this.countryCodeTarget.value = "";
    this.schoolTypeTarget.value = "";
    this.schoolTypeCustomTarget.value = "";
  }

  async fillForm() {
    const id = this.selectTarget.value;
    const url = this.urlValue.replace(":id", id);

    try {
      const response = await axios.get(url);
      const result = response.data;
      this.titleTarget.value = result.title;
      this.streetTarget.value = result.street;
      this.zipcodeTarget.value = result.zipcode;
      this.cityTarget.value = result.city;
      this.countryCodeTarget.value = result.countryCode;
      this.schoolTypeTarget.value = result.schoolType;

      this.allTarget.classList.remove("d-none");
    } catch (error) {
      // eslint-disable-next-line no-alert
      window.alert(
        "Es gab einen Fehler. Bitte versuchen Sie es später nochmal oder kontaktieren Sie unseren Support unter support@fobizz.com",
      );
      // eslint-enable-next-line no-alert
    }
  }
}
