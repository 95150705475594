import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submit-button-label"
export default class extends Controller {
  static targets = ["button"];

  static values = {
    option: String,
    result: String,
  };

  connect() {
    this.originalLabel = this.buttonTarget.value;
  }

  toggle(event) {
    if (this.optionValue === event.target.value) {
      this.buttonTarget.value = this.resultValue;
    } else {
      this.buttonTarget.value = this.originalLabel;
    }
  }
}
