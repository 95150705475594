import Clipboard from "stimulus-clipboard";

// Connects to data-controller="clipboard"
// Extended since we want to use an icon (html snippet) instead of text label
export default class extends Clipboard {
  static targets = ["button", "template"];

  static values = { successText: String };

  connect() {
    if (!this.hasButtonTarget) return;

    this.originalText = this.buttonTarget.innerHTML;
  }

  copied() {
    if (!this.hasButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.hasTemplateTarget) {
      const content = this.templateTarget.innerHTML;
      this.buttonTarget.innerHTML = content;
    } else {
      this.buttonTarget.innerText = this.successTextValue;
    }

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalText;
    }, this.successDurationValue);
  }
}
