export default function register() {
  let lastPosition = 0;
  const getTarget = () => document.querySelector(".course-sidebar");

  // store scrollTop
  function memorizeLastPosition() {
    const target = getTarget();

    if (target) {
      if (target.scrollTop > 0) {
        lastPosition = target.scrollTop;
      }
    }
  }

  // scroll to scrollTop, if previously stored
  function setScrollTop() {
    const target = getTarget();
    if (target) {
      if (lastPosition > 0 && lastPosition !== target.scrollTop) {
        target.scrollTop = lastPosition;
      }
    }
  }

  // scroll from previously stored scrollTop to new scrollTop
  function scrollToNewPosition() {
    const target = getTarget();
    if (target) {
      const currentListItem = target.querySelector(
        ".lesson-list__item--selected",
      );
      if (currentListItem) {
        currentListItem?.scrollIntoView({
          block: "center",
          inline: "nearest",
        });
        lastPosition = 0;
      }
    }
  }
  document.addEventListener("turbo:before-visit", memorizeLastPosition);
  document.addEventListener("turbo:render", setScrollTop);
  document.addEventListener("turbo:load", scrollToNewPosition);
}
