import { Controller } from "@hotwired/stimulus";
import { Popover } from "bootstrap";

// Connects to data-controller="popover"
export default class extends Controller {
  connect() {
    this.popover = new Popover(this.element, {
      html: true,
      sanitize: false,
      boundary: document.body,
    });
  }

  disconnect() {
    if (this.popover) {
      this.popover.dispose();
    }
  }
}
