import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="max-members-to-group-package"
export default class extends Controller {
  static targets = ["packageInput"];

  static values = {
    packages: Object,
  };

  selectPackage(event) {
    const maxMembersCount = event.target.value;
    const packageMembersCount = Object.keys(this.packagesValue).find(
      (membersCount) => maxMembersCount <= +membersCount,
    );

    if (packageMembersCount) {
      const packageName = this.packagesValue[packageMembersCount];
      this.select(packageName);
    } else {
      this.select("CUSTOM");
    }
  }

  select(packageName) {
    this.packageInputTargets.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.checked = input.value === packageName;
    });
  }
}
