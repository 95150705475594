import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable"
export default class extends Controller {
  static targets = ["source", "dest"];

  static values = {
    option: String,
  };

  connect() {
    this.toggleState(this.sourceTarget.value);
  }

  toggle(event) {
    this.toggleState(event.target.value);
  }

  toggleState(value) {
    this.destTargets.forEach((t) => {
      if (this.optionValue === value) {
        t.removeAttribute("disabled");
      } else {
        t.setAttribute("disabled", true);
      }
    });
  }
}
