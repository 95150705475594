import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gradeInput", "subjectInput"];

  static values = { customGrade: Boolean, customSubject: Boolean };

  connect() {
    if (this.customGradeValue) {
      this.gradeInputTarget.classList.remove("d-none");
    }

    if (this.customSubjectValue) {
      this.subjectInputTarget.classList.remove("d-none");
    }
  }

  change(ev) {
    if (ev.target.id === "material_grade_list_grade_type_other") {
      if (ev.target.checked) {
        this.gradeInputTarget.classList.remove("d-none");
      } else {
        this.gradeInputTarget.classList.add("d-none");
      }
    }

    if (ev.target.id === "material_subject_list_subject_type_other") {
      if (ev.target.checked) {
        this.subjectInputTarget.classList.remove("d-none");
      } else {
        this.subjectInputTarget.classList.add("d-none");
      }
    }
  }
}
