import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["form"];

  initialize() {
    this.debouncedReloadForm = debounce(this.reloadForm.bind(this), 500);
  }

  reloadForm() {
    this.formTarget.requestSubmit();
  }
}
