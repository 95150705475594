import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["link", "form"];

  initialize() {
    if (this.hasLinkTarget) {
      this.linkTarget.hidden = true;
    }
  }

  connect() {
    this.element.addEventListener("show.bs.modal", this.handleShow.bind(this));
  }

  disconnect() {
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener(
        "turbo:submit-end",
        this.handleSubmit.bind(this),
      );
    }
  }

  async handleShow() {
    if (this.hasLinkTarget) {
      this.linkTarget.click();

      if (this.frameElement) {
        await this.frameElement.loaded;
      }

      if (this.hasFormTarget) {
        this.formTarget.addEventListener(
          "turbo:submit-end",
          this.handleSubmit.bind(this),
        );
      }
    }
  }

  handleSubmit(event) {
    const { fetchResponse } = event.detail;
    if (fetchResponse && fetchResponse.response.status === 200) {
      Modal.getInstance(this.element).hide();
    }
  }

  get frameElement() {
    const id =
      this.hasLinkTarget && this.linkTarget.getAttribute("data-turbo-frame");

    return id && document.getElementById(id);
  }
}
